@use "~@cfx-dev/ui-components/dist/styles-scss/ui" as ui;

@import "~@cfx-dev/ui-components/dist/styles-scss/global.scss";
@import "themes.scss";

// Calculating serversList viewport size
// We want to keep it of 16:9 aspect ratio

@media (max-aspect-ratio: 16/9) {
	html {
		--width: 100vw;
		--height: calc(100vw / 1.777778);
	}
}

@media (min-aspect-ratio: 16/9) {
	html {
		--width: calc(100vh * 1.777778);
		--height: 100vh;
	}
}

html {
  // On 720p quant will be 4px
	--quant: calc(var(--height) * 0.0055);
  // Base font size, needs to be here as we then use rems to define various font sizes
  font-size: ui.q(2.5);
}

body {
  --backdrop-image: url(assets/images/bg2.jpg);

  background-image: var(--backdrop-image);
  background-size: cover;
  background-position: center center;
  background-color: black;

  @include ui.def('viewport-top',   calc((100vh - ui.use('height')) * 0.5));
  @include ui.def('viewport-bottom', calc(100vh - ui.use('viewport-top')));

  @include ui.def('viewport-left',  calc((100vw - ui.use('width')) * 0.5));
  @include ui.def('viewport-right',  calc(100vw - ui.use('viewport-left')));
}

#backdrop-outlet {
  width: 100vw;
  height: 100vh;

  background: ui.color-token('backdrop');
}
.cfxui-blurred-backdrop #backdrop-outlet {
  backdrop-filter: blur(20px) contrast(50%);
}

#cfxui-root {
  position: fixed;

  top: ui.use('viewport-top');
  left: ui.use('viewport-left');

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--width);
  height: var(--height);

  transition: transform .2s ease, filter .2s ease;
  transform-origin: left center;

  &.shrink {
    transform: translateX(-10px);
  }
}

.__inline_avatar {
  width: 1.5rem;
  height: 1.5rem;

  vertical-align: middle;

  margin-right: ui.offset('small');

  &::after {
    display: inline;
    content: ' ';
  }
}

::-webkit-scrollbar {
	width: ui.offset('normal');
}
::-webkit-scrollbar-thumb {
  border-top-left-radius: ui.border-radius('xsmall');
  border-bottom-left-radius: ui.border-radius('xsmall');

  background-color: ui.color-token('scrollbar');

  &:hover {
    background-color: ui.color-token('scrollbar-hover');
  }
  &:active {
    background-color: ui.color-token('scrollbar-active');
  }
}

* {
  box-sizing: border-box;
	padding: 0;
	margin: 0;
	user-select: none;
	outline: none;
  line-height: 1;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: currentColor;

  cursor: pointer;

  @include ui.animated();

  &.unstyle {
    text-decoration: none;
  }

  &:hover {
    text-decoration-color: ui.color-token('link-hover-decoration');
  }
}

button {
  font-size: var(--font-size);
}

html, body {
  width: 100vw;
  height: 100vh;

  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  color: ui.color-token('text');
  @include ui.font-family('secondary');
  @include ui.font-weight('bold');
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.8rem;
}
h6 {
  font-size: 0.7rem;
}

kbd {
  font-weight: 300;
  padding: 2px ui.q(.5);
  background-color: ui.color-token('kbd-background');
  border-radius: 2px;
  border: solid 1px ui.color-token('kbd-border');
}

p {
  padding: ui.offset('small') 0;

  line-height: 1.4;
}

li::marker {
  color: ui.color-token('li-marker');
}

.brand-icon {
  width: 1em;
  height: 1em;

  fill: currentColor;
  stroke: currentColor;

  &.mirror {
    transform: rotateY(180deg);
  }
}
