@use "~@cfx-dev/ui-components/dist/styles-scss/ui" as ui;

.relative {
  position: relative;
}

.list {
  flex-grow: 1;

  @include ui.def('backdrop-color', ui.color-token('island-blur-background'));
  @include ui.fake-backdrop-blur();

  box-shadow: 0 0 0 2px ui.color('main', 100, .25) inset;

  border-radius: ui.border-radius();
}

.pinned {
  flex-grow: 0;

  width: ui.viewport-width(.3);
}
