@use "~@cfx-dev/ui-components/dist/styles-scss/ui" as ui;

.root.specific {
  @include ui.font-size('xlarge');
}

.homepagebutton {
  display: flex;
  align-items: center;
}
