@use "~@cfx-dev/ui-components/dist/styles-scss/ui" as ui;

.preview {
  flex-grow: 1;

  display: flex;
  gap: ui.offset('normal');

  overflow: hidden;

  -webkit-mask-image: linear-gradient(90deg, black, black calc(100% - ui.offset('large')), transparent);
  mask-image: linear-gradient(90deg, black, black calc(100% - ui.offset('large')), transparent);
}
