@use "~@cfx-dev/ui-components/dist/styles-scss/ui" as ui;

.root {
  position: relative;
  flex-shrink: 0;

  &.type-list {
    height: 100%;
    aspect-ratio: 1;

    // overflow: hidden;

    .icon {
      border-radius: 2px;
    }
  }

  &.type-details {
    flex-grow: 0;

    &.size-normal {
      width: ui.q(21);
      height: ui.q(21);
    }
    &.size-small {
      width: ui.q(10);
      height: ui.q(10);
    }

    .icon {
      @include ui.border-radius('small');
    }
  }

  .icon {
    width: 100%;
    height: 100%;
  }

  .loader {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: ui.color-token('server-icon-loader-background');
  }
}
